.verticalTabItem {
  &:hover {
    .icon {
      path {
        fill: rgb(233, 236, 245);;
      }
    }
  }
  &:focus-visible {
    outline: 2px dashed rgb(233, 236, 245);
    outline-offset: -2px;
  }
}