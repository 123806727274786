.teammateInviteModal {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: 4px;
  width: 25rem;

  .topContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: rgb(26, 28, 34);
    width: unset;
    align-self: stretch;
  }
  
  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 1rem 1rem 1rem 1rem;
    width: unset;
    align-self: stretch;
  }
  
  .title {
    display: flex;
    width: unset;
    flex: 1 1 auto;
  }
  
  .closeButton {
    display: flex;
  }
  
  .border {
    display: block;
    width: unset;
    align-self: stretch;
  }
  
  .bottomContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 1rem 1rem 2rem 1rem;
    background-color: rgb(26, 28, 34);
    width: unset;
    align-self: stretch;
  }
  
  .emailField {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .addButton {
    display: flex;
    margin-top: 1.5rem;
  }
  
  .add {
    display: none;
  }
  
  @media (min-width: 990px) {
    border-radius: 8px;
    width: 30rem;

    .addButton {
      display: none;
      margin-top: 0;
    }
    
    .add {
      display: flex;
      margin-top: 1.5rem;
    }
    
  }
}
