@import './resources/scss/fonts.scss';
@import './reset.css';

// MANUAL OVERRIDE
.layout {
  height: 100%;
}

// MANUAL OVERRIDE
.notificationContainer {
  top: 4rem;
  left: 50%;;
  transform: translateX(-50%);;
}
