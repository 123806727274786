.teamCardAddedButton {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 1rem 1rem 1rem 1rem;
  border-radius: 4px;
  background-color: rgb(26, 28, 34);
  outline: 1px solid rgb(46, 51, 62);
  outline-offset: 0;
  width: 15rem;
  height: 10.625rem;

  .content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: unset;
    flex: 1 1 auto;
  }
  
  .topContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: unset;
    align-self: stretch;
  }
  
  .header {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    width: unset;
    align-self: stretch;
  }
  
  .spacerContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    width: unset;
    flex: 1 1 auto;
  }
  
  .initials {
    display: flex;
    width: 3.75rem;
    height: 3.75rem;
  }
  
  .buttonContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    width: unset;
    flex: 1 1 auto;
  }
  
  .iconDropDown {
    display: flex;
  }
  
  .titleWithDescription {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 0.25rem;
  }
  
  .role {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 1rem;
  }
  
  @media (min-width: 990px) {
    padding: 1rem 1rem 1.5rem 1rem;
    outline: 1px solid rgb(26, 28, 34);
    height: 13.75rem;

    .content {
      justify-content: center;
    }
    
    .initials {
      width: 5rem;
      height: 5rem;
    }
    
    .titleWithDescription {
      margin-top: 0.5rem;
    }
    
    .role {
      margin-top: 1.5rem;
    }
    
  }
}

.teamCardAddedNoButton {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 1rem 1rem 1rem 1rem;
  border-radius: 4px;
  background-color: rgb(26, 28, 34);
  outline: 1px solid rgb(46, 51, 62);
  outline-offset: 0;
  width: 15rem;
  height: 10.625rem;

  .content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: unset;
    flex: 1 1 auto;
  }
  
  .topContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: unset;
    align-self: stretch;
  }
  
  .header {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    width: unset;
    align-self: stretch;
  }
  
  .initials {
    display: flex;
    width: 3.75rem;
    height: 3.75rem;
  }
  
  .titleWithDescription {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 0.25rem;
  }
  
  .role {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 1rem;
  }
  
  @media (min-width: 990px) {
    padding: 1rem 1rem 1.5rem 1rem;
    outline: 1px solid rgb(26, 28, 34);
    height: 13.75rem;

    .content {
      justify-content: center;
    }
    
    .initials {
      width: 5rem;
      height: 5rem;
    }
    
    .titleWithDescription {
      margin-top: 0.5rem;
    }
    
    .role {
      margin-top: 1.5rem;
    }
    
  }
}

.teamCardPendingButton {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem 1rem 1rem 1rem;
  border-radius: 4px;
  background-color: rgb(26, 28, 34);
  outline: 1px solid rgb(46, 51, 62);
  outline-offset: 0;
  width: 15rem;
  height: 10.625rem;

  .topContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: unset;
    height: unset;
    align-self: stretch;
    flex: 1 1 auto;
  }
  
  .header {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
  }
  
  .spacerContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    width: 4.625rem;
  }
  
  .icon {
    display: block;
    width: 3.75rem;
    height: 3.75rem;
  }
  
  .buttonContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    width: 4.625rem;
  }
  
  .iconDropDown {
    display: flex;
  }
  
  .email {
    display: flex;
    width: 13rem;
    margin-top: 0.25rem;
  }
  
  .status {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  @media (min-width: 990px) {
    padding: 1rem 1rem 1.5rem 1rem;
    outline: 1px solid rgb(26, 28, 34);
    height: 13.75rem;

    .header {
      justify-content: center;
    }
    
    .spacerContainer {
      width: 4rem;
    }
    
    .icon {
      width: 5rem;
      height: 5rem;
    }
    
    .buttonContainer {
      width: 4rem;
    }
    
    .email {
      margin-top: 0.5rem;
    }
    
  }
}

.teamCardPendingNoButton {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem 1rem 1rem 1rem;
  border-radius: 4px;
  background-color: rgb(26, 28, 34);
  outline: 1px solid rgb(46, 51, 62);
  outline-offset: 0;
  width: 15rem;
  height: 10.625rem;

  .topContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: unset;
    height: unset;
    align-self: stretch;
    flex: 1 1 auto;
  }
  
  .header {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
  }
  
  .icon {
    display: block;
    width: 3.75rem;
    height: 3.75rem;
  }
  
  .email {
    display: flex;
    width: 13rem;
    margin-top: 0.25rem;
  }
  
  .status {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  @media (min-width: 990px) {
    padding: 1rem 1rem 1.5rem 1rem;
    outline: 1px solid rgb(26, 28, 34);
    height: 13.75rem;

    .header {
      justify-content: center;
    }
    
    .icon {
      width: 5rem;
      height: 5rem;
    }
    
    .email {
      margin-top: 0.5rem;
    }
    
  }
}
