.accountMenuDropDownCollapsed {
  &:hover {
    .initials {
      div > * {
        color: rgb(233, 236, 245);
      }
    }
    .name {
      p {
        color: rgb(233, 236, 245);
      }
    }
  }
}

.accountMenuDropDownExpanded {
  .initials {
    div > * {
      color: rgb(233, 236, 245);
    }
  }
  .name {
    p {
      color: rgb(233, 236, 245);
    }
  }
}
