.appDropDown {
  width: 100%;
  height: 100%;

  &:focus-visible {
    outline: 2px dashed rgb(233, 236, 245);
    outline-offset: -2px;
    border: none;
  }

  &:hover {
    .icon {
      path {
        fill: rgb(233, 236, 245);;
      }
    }
    .appName {
      p {
        color: rgb(233, 236, 245);
      }
    }
  }
}
