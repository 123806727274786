.accountMenuDropDownCollapsed {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 0.375rem 0.5rem 0.375rem 0.5rem;
  background-color: rgb(26, 28, 34);

  .initials {
    display: flex;
    width: 1.5rem;
    height: 1.5rem;
  }
  
  .name {
    display: flex;
    margin-left: 0.5rem;
  }
  
  .icon {
    display: block;
    width: 1rem;
    height: 1rem;
    margin-left: 0.5rem;
  }
  
  &:focus-visible {
    outline: 2px dashed rgb(233, 236, 245);
    outline-offset: 0;
  
  }
  @media (min-width: 1440px) {
    padding: 0.5rem 0.75rem 0.5rem 0.75rem;

    .initials {
      width: 2rem;
      height: 2rem;
    }
    
    .icon {
      width: 1.5rem;
      height: 1.5rem;
    }
    
  &:focus-visible {
    outline: 2px dashed rgb(233, 236, 245);
    outline-offset: 0;
  
  }
  }
}

.accountMenuDropDownExpanded {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 0.375rem 0.5rem 0.375rem 0.5rem;
  background-color: rgb(65, 71, 85);

  .initials {
    display: flex;
    width: 1.5rem;
    height: 1.5rem;
  }
  
  .name {
    display: flex;
    margin-left: 0.5rem;
  }
  
  .icon {
    display: block;
    width: 1rem;
    height: 1rem;
    margin-left: 0.5rem;
  }
  
  @media (min-width: 1440px) {
    padding: 0.5rem 0.75rem 0.5rem 0.75rem;

    .initials {
      width: 2rem;
      height: 2rem;
    }
    
    .icon {
      width: 1.5rem;
      height: 1.5rem;
    }
    
  }
}
