.editorLayout {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: rgb(7, 7, 9);

  .topNav {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
}
