.mainLayout {
  .content {
    height: calc(100% - 4rem);
  }

  .teamsPage {
    height: 100%;
  }

  .leftNav {
    z-index: 1;
  }
}
