.verticalTabList {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  width: 15.6875rem;

  .verticalTabItem {
    display: flex;
  }
  
  @media (min-width: 1440px) {
    width: unset;

  }
}
