.initialsLarge {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  background-color: rgb(65, 71, 85);
  width: 3.75rem;
  height: 3.75rem;

  .text {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  @media (min-width: 1440px) {
    width: 5rem;
    height: 5rem;

  }
}

.initialsSmall {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.625rem 0rem 0.625rem 0rem;
  border-radius: 100px;
  background-color: rgb(65, 71, 85);
  outline: 1px solid rgb(46, 51, 62);
  outline-offset: 0;
  width: 1.5rem;
  height: 1.5rem;

  .text {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  @media (min-width: 1440px) {
    width: 2rem;
    height: 2rem;

  }
}
