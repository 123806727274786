.teamsBlock {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 47rem;

  .teamCardList {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  @media (min-width: 1440px) {
    width: 64.375rem;

  }
}
