.teamsPage {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: rgb(7, 7, 9);

  .pageHeader {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .pageContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    padding: 0rem 0rem 2rem 0rem;
    width: unset;
    height: unset;
    align-self: stretch;
    flex: 1 1 auto;
  }
  
  .teamsBlock {
    display: flex;
    width: 47rem;
    height: unset;
    align-self: stretch;
  }
  
  @media (min-width: 1440px) {
    height: 64rem;

    .pageContainer {
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      padding: 0rem 0rem 3.5rem 0rem;
    }
    
    .teamsBlock {
      width: 64.375rem;
      flex: 1 1 auto;
    }
    
  }
}

// MANUAL OVERRIDE
.modalWrapper {
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%,-50%);
  transform: translate(-50%,-50%);
}
