.pageHeader {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 2rem 2rem 1rem 2rem;
  background-color: rgb(7, 7, 9);

  .text {
    display: flex;
    width: unset;
    flex: 1 1 auto;
  }
  
  .button {
    display: flex;
    margin-left: 1.5rem;
  }
  
  @media (min-width: 1440px) {
    padding: 3rem 4rem 1.5rem 4rem;

  }
}
