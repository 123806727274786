.titleWithDescription {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  .title {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .subtitle {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 0.125rem;
  }
  
  @media (min-width: 583px) {

    .subtitle {
      margin-top: 0.25rem;
    }
    
  }
}
