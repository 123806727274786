.deleteTeammateModal {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: 4px;
  width: 27.5rem;

  .topContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: rgb(26, 28, 34);
    width: unset;
    align-self: stretch;
  }
  
  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 1rem 1rem 1rem 1rem;
    width: unset;
    align-self: stretch;
  }
  
  .title {
    display: flex;
    width: unset;
    flex: 1 1 auto;
  }
  
  .closeButton {
    display: flex;
  }
  
  .border {
    display: block;
    width: unset;
    align-self: stretch;
  }
  
  .bottomContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 1rem 1rem 3rem 1rem;
    background-color: rgb(26, 28, 34);
    width: unset;
    align-self: stretch;
  }
  
  .message {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .teamCard {
    display: flex;
    width: 15rem;
    height: 10.625rem;
    margin-top: 1.5rem;
  }
  
  .buttonPair {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 1.5rem;
  }
  
  .deleteButton {
    display: flex;
  }
  
  .cancelButton {
    display: flex;
    margin-left: 0.5rem;
  }
  
  @media (min-width: 990px) {
    width: 31.5rem;

    .bottomContent {
      padding: 1rem 1rem 3.5rem 1rem;
    }
    
    .teamCard {
      height: 13.75rem;
      margin-top: 2rem;
    }
    
    .buttonPair {
      margin-top: 2rem;
    }
    
    .cancelButton {
      margin-left: 0.75rem;
    }
    
  }
}
