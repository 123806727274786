.leftNav {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: rgb(26, 28, 34);
  width: 3.0625rem;

  .menuContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    height: unset;
    align-self: stretch;
  }
  
  .serviceMenu {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    align-self: stretch;
  }
  
  .serviceList {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .settingsMenu {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  
  .settingsList {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .border {
    display: block;
    width: 0.0625rem;
    height: unset;
    align-self: stretch;
  }
  
}
