.topNav {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: rgb(26, 28, 34);
  height: 3.0625rem;

  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: unset;
    align-self: stretch;
  }
  
  .leftContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    flex: 1 1 auto;
  }
  
  .logoContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0.75rem 0.75rem 0.75rem 0.75rem;
  }
  
  .spindlLogo {
    display: block;
    width: 3.796875rem;
    height: 1.5rem;
  }
  
  .border {
    display: block;
    width: 0.0625rem;
    height: unset;
    align-self: stretch;
  }
  
  .appDropDown {
    display: flex;
    height: unset;
    align-self: stretch;
  }
  
  .accountMenuDropDown {
    display: flex;
    width: unset;
    flex: 1 1 auto;
  }
  
  .border1 {
    display: block;
    width: unset;
    align-self: stretch;
  }
  
  @media (min-width: 1440px) {
    align-items: flex-start;

    .accountMenuDropDown {
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      border-radius: 4px;
      background-color: rgb(26, 28, 34);
    }
    
  }
}
