.editorLayout {
  .content {
    width: 100%;
    height: 100%;
  }
}

.showOverflow {
  overflow: auto;
}

.hideOverflow {
  overflow: hidden;
}
