.teamCard {
  border: 1px solid rgb(46, 51, 62);

  .content {
    width: 100%;
  }

  .header {
    align-self: stretch;
  }

  .spacerContainer {
    width: 1.25rem;
    flex: 1 1 auto;
  }

  .buttonContainer {
    width: 1.25rem;
    flex: 1 1 auto;
  }

  .email {
    align-self: stretch;
    width: unset;

    p {
      align-self: stretch;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      display: inline-block;
    }
  }

  .titleWithDescription {
    h3, p {
      align-self: stretch;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      display: inline-block;
    }
  }
}
