.appDropDownCollapsed {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background-color: rgb(26, 28, 34);

  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 0.75rem 0.75rem 0.75rem 0.75rem;
    width: unset;
    align-self: stretch;
  }
  
  .appName {
    display: flex;
  }
  
  .icon {
    display: block;
    width: 1.25rem;
    height: 1.25rem;
    margin-left: 0.5rem;
  }
  
  &:active {
    background-color: rgb(65, 71, 85);
  
  }
  &:focus-visible {
    border: 2px dashed rgb(233, 236, 245);
    outline-offset: 0;
  
  }
}

.appDropDownExpanded {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background-color: rgb(65, 71, 85);

  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 0.75rem 0.75rem 0.75rem 0.75rem;
    width: unset;
    align-self: stretch;
  }
  
  .appName {
    display: flex;
  }
  
  .icon {
    display: block;
    width: 1.25rem;
    height: 1.25rem;
    margin-left: 0.5rem;
  }
  
  &:focus-visible {
    outline: 2px dashed rgb(233, 236, 245);
    outline-offset: 0;
  
  }
}
